import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace MapEditorAPI {
  export class GetCoordinateSystemsByUserId implements APIRequest<string> {
    response: string;
    path = '/mapeditor/coordinate_systems_user/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (userId: number) {
      this.path += userId
    }
  }

  export class GetCoordinateSystemBySrid implements APIRequest<string> {
    response: string;
    path = '/mapeditor/coordinate_systems/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (srid: number) {
      this.path += srid
    }
  }

  export class ResetImportVTLayer implements APIRequest<string> {
    response: string;
    path = '/mapeditor/vectortiles/import';
    method = HTTPMethod.DELETE;
    parse = (data: AxiosResponse) => data.data;
    params = {}
    hideNotification = true

    constructor (uploadId: string) {
      this.params = { upload_id: uploadId }
    }
  }

  export class GetVTLayerFeaturesAttributes implements APIRequest<string> {
    response: string;
    path = '/mapeditor/vectortiles/attributes/';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data;
    params = {}
    hideNotification = true

    constructor (entityId: number, fields: Array<string>, ids: Array<number>) {
      this.path += entityId
      this.params = { fields: fields, record_ids: ids }
    }
  }
}
